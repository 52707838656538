<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="批量添加品类"
      width="750px"
      top="5vh"
      :visible.sync="showDialog"
      @close="close"
    >
      <div class="content">
        <el-checkbox
          v-for="(item, index) in data"
          :key="index"
          v-model="item.is_close"
          :label="item.class_name"
          border
          style="margin-top: 10px"
        ></el-checkbox>
      </div>
      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">提交</el-button>
        <el-button @click="close">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { getQuickAddList, subQuickAdd } from '@/api/category'
  export default {
    name: '',
    components: {},
    data() {
      return {
        showDialog: false,
        data: [],
        checked: {},
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        }
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        let { data } = await getQuickAddList()
        console.log('快速添加列表数据')
        console.log(data)
        this.data = data
      },
      submit() {
        let checked = this.data.filter((item) => item.is_close == true)
        let checkedId = []
        checked.forEach((item) => {
          checkedId.push(item.id)
        })
        let endData = checkedId.join(',')
        if (endData !== '') {
          subQuickAdd({ ids: endData }).then((res) => {
            if (res.code === 200) {
              this.$message.success('提交成功.')
              this.$emit('refresh')
              this.close()
            }
          })
        } else {
          this.$message.error('未选中任何选项')
        }
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .content {
    min-height: 260px;
    padding: 0px 15px;
  }
</style>
