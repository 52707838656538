<template>
  <div class="orderTest-container">
    <div class="btn">
      <el-button @click="quickAdd">快速添加</el-button>
      <el-button @click="addCate">添加品类</el-button>
    </div>
    <div class="table-wrapper" style="margin-top: 15px">
      <el-table stripe :data="list">
        <!-- 序号 -->
        <el-table-column prop="" align="center" label="序号" width="60px">
          <template #default="{ $index }">
            <span>{{ $index + 1 }}</span>
          </template>
        </el-table-column>
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          width="45"
        ></el-table-column>

        <el-table-column
          v-for="(colItem, index) in finallyColumns"
          :key="index"
          align="center"
          :label="colItem.label"
          :width="colItem.width"
        >
          <!-- 排序 -->
          <template v-if="colItem.label === '排序'" #default="{ row }">
            <el-input
              v-model.trim="row.sort"
              @change="blur_(row.id, row.sort)"
            ></el-input>
          </template>
          <template v-else #default="{ row }">
            {{ row[colItem.prop] }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="120px"
          align="center"
          fixed="right"
        >
          <template #default="{ $index, row }">
            <!-- <el-button type="text" @click.native.prevent="addRow(row)">
              添加
            </el-button> -->
            <el-button type="text" @click.native.prevent="editRow($index, row)">
              编辑
            </el-button>
            <el-button
              type="text"
              @click.native.prevent="deleteRow($index, row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryForm.pageNo"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="total"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>
    <quick-add ref="quickAdd" @refresh="fetchData"></quick-add>
    <add-category ref="addCategory" @refresh="fetchData"></add-category>
    <row-edit ref="rowEdit" @refresh="fetchData"></row-edit>
  </div>
</template>

<script>
  import _ from 'lodash'
  import {
    getCategoryTableData,
    refreshSort,
    deleteCategory,
  } from '@/api/category'
  import QuickAdd from './components/quickAdd'
  import AddCategory from './components/addCategory'
  import RowEdit from './components/edit'
  export default {
    name: 'CategoryDetail',
    components: {
      QuickAdd,
      AddCategory,
      RowEdit,
    },
    data() {
      return {
        list: [],
        queryForm: { pageNo: 1, pageSize: 10 },
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        checkList: ['排序', '品类', '状态'],
        columns: [
          {
            order: 1,
            label: '排序',
            align: 'center',
            width: '70px',
            prop: 'sort',
            sortable: false,
          },
          {
            order: 2,
            prop: 'class_name',
            label: '品类',
            width: 'auto',
          },
          {
            order: 3,
            prop: 'is_close_txt',
            label: '状态',
            width: 'auto',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      quickAdd() {
        this.$refs['quickAdd'].showDialog = true
      },
      addCate() {
        this.$refs['addCategory'].showDialog = true
      },
      deleteRow(index, row) {
        console.log('删除')
        console.log(row.id)
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteCategory({ id: row.id }).then((res) => {
              if (res.code === 200) {
                this.$message.success('删除成功')
                this.fetchData()
              } else {
                this.$message.error('删除失败')
              }
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      editRow(index, row) {
        console.log('编辑')
        console.log(row.id)
        this.$refs['rowEdit'].row = row
        this.$refs['rowEdit'].showDialog = true
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      async fetchData() {
        let { data, totalCount } = await getCategoryTableData(this.queryForm)
        console.log('品类数据')
        console.log(data)
        this.total = Number(totalCount)
        this.list = data
      },
      // 排序输入框失去焦点 排序
      blur_(rowId, rowSort) {
        refreshSort({
          id: rowId,
          sort: rowSort,
        }).then((res) => {
          if (res.code == 200) {
            this.$message.success('排序成功')
            this.fetchData()
          }
        })
      },
    },
  }
</script>
