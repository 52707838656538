import request from '@/utils/request'

export function getCategoryTableData(data) {
  return request({
    url: '/baseAdmin/goods-class/index',
    method: 'post',
    data,
  })
}

export function getQuickAddList(data) {
  return request({
    url: '/baseAdmin/goods-class/quick-view',
    method: 'post',
    data,
  })
}

export function subQuickAdd(data) {
  return request({
    url: '/baseAdmin/goods-class/quick-create',
    method: 'post',
    data,
  })
}
export function creatCategory(data) {
  return request({
    url: '/baseAdmin/goods-class/create',
    method: 'post',
    data,
  })
}

// 排序

export function refreshSort(data) {
  return request({
    url: '/baseAdmin/goods-class/sort',
    method: 'post',
    data,
  })
}

// 删除
export function deleteCategory(data) {
  return request({
    url: '/baseAdmin/goods-class/delete',
    method: 'post',
    data,
  })
}

// 编辑
export function editCategory(data) {
  return request({
    url: '/baseAdmin/goods-class/update',
    method: 'post',
    data,
  })
}
