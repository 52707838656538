var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "批量添加品类",
            width: "750px",
            top: "5vh",
            visible: _vm.showDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            _vm._l(_vm.data, function (item, index) {
              return _c("el-checkbox", {
                key: index,
                staticStyle: { "margin-top": "10px" },
                attrs: { label: item.class_name, border: "" },
                model: {
                  value: item.is_close,
                  callback: function ($$v) {
                    _vm.$set(item, "is_close", $$v)
                  },
                  expression: "item.is_close",
                },
              })
            }),
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("提交")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }