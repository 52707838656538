<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="编辑"
      width="750px"
      top="15vh"
      :visible.sync="showDialog"
      @close="close"
    >
      <!-- {{ row.id }} -->
      <div class="content">
        <el-form ref="form" :model="form" :rules="rules" label-width="55px">
          <el-form-item label="名称" prop="class_name">
            <el-input v-model="form.class_name" style="width: 260px"></el-input>
          </el-form-item>
          <div class="check">
            <el-radio v-model="form.is_close" :label="0">正常</el-radio>
            <el-radio v-model="form.is_close" :label="1">停用</el-radio>
          </div>
        </el-form>
      </div>

      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="close">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { editCategory } from '@/api/category'
  export default {
    name: 'RowEdit',
    components: {},
    data() {
      return {
        row: {},
        form: {},
        showDialog: false,
        rules: {
          class_name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
            {
              min: 1,
              max: 10,
              message: '长度在 1 到 10 个字符',
              trigger: 'blur',
            },
          ],
        },
      }
    },

    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          console.log(this.row)
          let a = JSON.parse(JSON.stringify(this.row))
          this.form = a
        }
      },
    },
    methods: {
      save() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            editCategory(this.form).then((res) => {
              if (res.code === 200) {
                this.$message.success('修改成功')
                this.showDialog = false
                this.$emit('refresh')
              } else {
                this.$message.error('修改失败')
              }
            })
          } else {
            console.log('验证失败')
            return false
          }
        })
      },
      close() {
        this.showDialog = false
      },
      // fetchData() {},
    },
  }
</script>
<style lang="scss" scoped>
  .content {
    display: flex;
    justify-content: center;
    min-height: 160px;
    padding: 0px 15px;
  }
  .check {
    margin-top: 55px;
    text-align: center;
  }
</style>
