var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c("el-button", { on: { click: _vm.quickAdd } }, [
            _vm._v("快速添加"),
          ]),
          _c("el-button", { on: { click: _vm.addCate } }, [_vm._v("添加品类")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-wrapper", staticStyle: { "margin-top": "15px" } },
        [
          _c(
            "el-table",
            { attrs: { stripe: "", data: _vm.list } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  align: "center",
                  label: "序号",
                  width: "60px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      return [_c("span", [_vm._v(_vm._s($index + 1))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", type: "selection", width: "45" },
              }),
              _vm._l(_vm.finallyColumns, function (colItem, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    align: "center",
                    label: colItem.label,
                    width: colItem.width,
                  },
                  scopedSlots: _vm._u(
                    [
                      colItem.label === "排序"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.blur_(row.id, row.sort)
                                    },
                                  },
                                  model: {
                                    value: row.sort,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        row,
                                        "sort",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "row.sort",
                                  },
                                }),
                              ]
                            },
                          }
                        : {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(" " + _vm._s(row[colItem.prop]) + " "),
                              ]
                            },
                          },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "120px",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.editRow($index, row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.deleteRow($index, row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryForm.pageNo,
              layout: _vm.layout,
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c("quick-add", { ref: "quickAdd", on: { refresh: _vm.fetchData } }),
      _c("add-category", {
        ref: "addCategory",
        on: { refresh: _vm.fetchData },
      }),
      _c("row-edit", { ref: "rowEdit", on: { refresh: _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }