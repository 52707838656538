<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="添加品类"
      width="750px"
      top="15vh"
      :visible.sync="showDialog"
      @close="close"
    >
      <div class="content">
        <el-form ref="form" :model="form" :rules="rules" label-width="55px">
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name" style="width: 260px"></el-input>
          </el-form-item>
          <div class="check">
            <el-radio v-model="status" :label="0">正常</el-radio>
            <el-radio v-model="status" :label="1">停用</el-radio>
          </div>
        </el-form>
      </div>

      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveAdd">保存并新增</el-button>
        <el-button @click="justSave">保存</el-button>
        <el-button @click="close">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { creatCategory } from '@/api/category'
  export default {
    name: '',
    components: {},
    data() {
      return {
        showDialog: false,
        form: {
          name: '',
        },
        status: 0,
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
            {
              min: 1,
              max: 10,
              message: '长度在 1 到 10 个字符',
              trigger: 'blur',
            },
          ],
        },
      }
    },
    computed: {},
    methods: {
      saveAdd() {
        this.save(0)
        // creatCategory({
        //   is_close: this.status,
        //   class_name: this.form.name,
        // }).then((res) => {
        //   if (res.code === 200) {
        //     this.$message.success('添加并新增成功')
        //     this.form.name = ''
        //     this.$emit('refresh')
        //   } else {
        //     this.$message.error('添加并新增失败')
        //   }
        // })
      },
      justSave() {
        this.save(1)
      },
      save(addnew = 0) {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            creatCategory({
              is_close: this.status,
              class_name: this.form.name,
            }).then((res) => {
              if (res.code === 200) {
                this.$message.success('添加成功')
                if (addnew) {
                  this.showDialog = false
                }
                this.form.name = ''
                this.$emit('refresh')
                this.close()
              } else {
                this.$message.error('添加失败')
              }
            })
          } else {
            console.log('验证失败')
            return false
          }
        })
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .content {
    display: flex;
    justify-content: center;
    min-height: 160px;
    padding: 0px 15px;
  }
  .check {
    margin-top: 55px;
    text-align: center;
  }
</style>
